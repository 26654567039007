import React, { useEffect, useState } from "react";

import { getTicketList } from '../services/Tickets';
import Loader from "../components/Loader";
import { ticketKey } from "../utils/labelMap";
import DynamicTable from "../components/DynamicTable";

export default function Tickets() {
    const [loading, setLoading] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            const data = await getTicketList();
            if (data.error) {
                setErrorMessage(data.error);
                setLoading(false)
            } else {
                setTickets(data);
                setErrorMessage(false);
                setLoading(false)
            }
        }
        fetchData();
    }, []);

    const openTicketModal = () => {
        console.log("not implemented");
    }

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-20">
            {loading && (
            <Loader />
            )}
            {errorMessage && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                <strong className="font-bold">Error: </strong>
                <span className="block sm:inline">{errorMessage}</span>
            </div>
            )}
            {!errorMessage && (
            <div>
                <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">Tickets</h1>
                    <p className="mt-2 text-sm text-gray-700">A list of all tickets.</p>
                </div>
            </div>
            {errorMessage && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mt-2 rounded relative" role="alert">
                <strong className="font-bold">Error: </strong>
                <span className="block sm:inline">{errorMessage}</span>
                </div>
            )}
            <DynamicTable
                rowData={tickets}
                legend={ticketKey}
                loading={loading}
                activateDeactivateRow={openTicketModal}
                openEditRowModal={openTicketModal}
                removeRowEdit={true}
                openDetailRowModal={openTicketModal}
                mobileLegend={ticketKey}
                allowExpandOptions={true}
                hasChildData={true}
                removeChildEdit={true}
                childTitle={"Items"}
                noDataMessage={"No Tickets have been added"}
            />
            </div>
            )}
        </div>
    )
}