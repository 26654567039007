import axios from 'axios';
import { formatErrorResponse } from '../utils/formatErrors';

export const getStations = async () => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.get('/Station', config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const createStation = async (data) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.post('/Station', data, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const updateStation = async (data) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.put('/Station', data, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const createStationAttribute = async (data) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.post('/StationAttribute', data, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const updateStationAttribute = async (data) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.put('/StationAttribute', data, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}