
import React, { useState, useEffect } from "react";

import { getLocations, createLocation, updateLocation, createLocationAttribute, updateLocationAttribute} from '../../services/Locations';
import CreateEditModal from "../../components/CreateEditModal";
import Loader from "../../components/Loader";
import { locationForm , attributeForm} from "../../utils/forms";
import { locationKey } from "../../utils/labelMap";
import DynamicTable from "../../components/DynamicTable";

export default function Locations() {
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const [creatingLocation, setCreatingLocation] = useState(false);
  const [editingLocation, setEditingLocation] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [pageErrorMessage, setPageErrorMessage] = useState('');
  const [selectedData, setSelectedData] = useState([]);
  const [creatingAttribute, setCreatingAttribute] = useState(false);
  const [editingAttribute, setEditingAttribute] = useState(false);
  const [activeLocationId, setActiveLocationId] = useState(-1);

  //let user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      const locations = await getLocations();
      if (locations.error) {
        setPageErrorMessage(locations);
        setLoading(false)
      } else {
        setLocations(locations);
        setPageErrorMessage(false);
        setLoading(false)
      }
    }
    fetchData();
  }, []);

  const openEditLocationModal = (e, data) => {
    e.stopPropagation();
    setSelectedData(data);
    setEditingLocation(true);
  }

  const openLocationModal = () => {
    setSelectedData({});
    setCreatingLocation(true);
  }

  const openUserDetailModal = (e, data) => {
    e.stopPropagation();
    setSelectedData(data);
  }

  const closeModal = () => {
    setCreatingLocation(false);
    setEditingLocation(false);
    setCreatingAttribute(false);
    setEditingAttribute(false);
    setErrorMessage('');
  }

  const handleBool = (name, boolValue) => {
    setSelectedData(selectedData => ({
      ...selectedData,
      [name]: boolValue
    }));
  };

  const handleChange = e => {
    let { name, value } = e.target;
    setSelectedData(selectedData => ({
      ...selectedData,
      [name]: value
    }));
  };

  const updateLocationSubmit = async () => {
    setErrorMessage('');
    const response = await updateLocation(selectedData);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setEditingLocation(false);
    setLocations(locations.map(location => location.id === response.id ? response : location));
  }

  const createLocationSubmit = async () => {
    setErrorMessage('');
    const response = await createLocation(selectedData);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setCreatingLocation(false);
    const newLocations = [...locations, response];
    setLocations(newLocations);
  }

  const openEditAttributeModal = (locationAttribute) => {
    setEditingAttribute(true);
    setSelectedData(locationAttribute);
    setActiveLocationId(locationAttribute.parentId);
  }

  const openCreateAttributeModal = (locationId) => {
    setCreatingAttribute(true);
    setSelectedData(locationId);
    setActiveLocationId(locationId);
  }

  const createAttributeSubmit = async () => {
    setErrorMessage('');
    let attributeData = { ...selectedData };
    attributeData.parentId = activeLocationId;
    const response = await createLocationAttribute(attributeData);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setCreatingAttribute(false);
    let newLocations = [...locations];
    const locationIndex = locations.findIndex(location => location.id === response.result.parentId);
    const attributes = newLocations[locationIndex].attributes;
    newLocations[locationIndex].attributes = [...attributes, response.result];
    setLocations(newLocations);
    setActiveLocationId(-1);
  }

  const updateAttributeSubmit = async () => {
    setErrorMessage('');
    let attributeData = { ...selectedData };
    attributeData.parentId = activeLocationId;
    const response = await updateLocationAttribute(attributeData);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setEditingAttribute(false);
    const locationIndex = locations.findIndex(location => location.id === response.result.parentId);
    const location = locations.filter(location => location.id === response.result.parentId)[0];
    const attributeIndex = location.attributes.findIndex(attribute => attribute.id === response.result.id);
    let newLocations = [...locations];
    newLocations[locationIndex].attributes[attributeIndex] = response.result;
    setLocations(newLocations);
  }

  const handleSelect = e => {
    let { name, value } = e.target;
    if (value === 'default') {
      let data = {...selectedData};
      let key;
      let field = e.target.field;
      if (name === 'locationIdName') {
        data['locationId'] = field;
      delete data[key];
      setSelectedData(data);
      return;
      }
    }
    if (name === "locationIdName") {
      const location = locations.filter(c => c.locationId === value);
      setSelectedData(selectedData => ({
        ...selectedData,
        [name]: value,
        locationId: location[0].id
      }));
    }
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-20">
      {loading && (
        <Loader />
      )}
      {pageErrorMessage && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{pageErrorMessage}</span>
        </div>
      )}
      {!pageErrorMessage && (
        <div>
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">Locations</h1>
              <p className="mt-2 text-sm text-gray-700">List of all Locations</p>
            </div>
            <div className="sm:mt-0 mt-4 sm:ml-16 sm:flex-none">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
                onClick={() => openLocationModal()}
              >
                Add Location
              </button>
            </div>
          </div>
        {errorMessage && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mt-2 rounded relative" role="alert">
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{errorMessage}</span>
          </div>
        )}
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <DynamicTable
              rowData={locations}
              legend={locationKey}
              loading={loading}
              hasChildData={true}
              openEditRowModal={openEditLocationModal}
              openEditChildModal={openEditAttributeModal}
              openCreateChildModal={openCreateAttributeModal}
              openDetailRowModal={openUserDetailModal}
              mobileLegend={locationKey}
              noDataMessage={"No Locations have been added"}
              allowExpandOptions={true}
            />
          </div>
        </div>
        {creatingLocation && (
          <CreateEditModal 
            form={locationForm}
            setData={handleChange}
            close={closeModal}
            setBool={handleBool}
            setSelect={handleSelect}
            data={selectedData}
            labels={locationKey.dataMap}
            submit={createLocationSubmit}
            errorMessage={errorMessage}
            title={"Create Location"}
          />
        )}
        {editingLocation && (
          <CreateEditModal 
            form={locationForm}
            setData={handleChange}
            close={closeModal}
            setBool={handleBool}
            data={selectedData}
            labels={locationKey.dataMap}
            submit={updateLocationSubmit}
            errorMessage={errorMessage}
            title={"Edit Location"}
          />
        )}
        {creatingAttribute && (
          <CreateEditModal 
            form={attributeForm}
            setData={handleChange}
            setBool={handleBool}
            close={closeModal}
            data={selectedData}
            labels={locationKey.childMap}
            submit={createAttributeSubmit}
            errorMessage={errorMessage}
            title={"Create Attribute"}
          />
        )}
        {editingAttribute && (
          <CreateEditModal 
            form={attributeForm}
            setData={handleChange}
            setBool={handleBool}
            close={closeModal}
            data={selectedData}
            labels={locationKey.childMap}
            submit={updateAttributeSubmit}
            errorMessage={errorMessage}
            title={"Edit Attribute"}
          />
        )}
      </div>
      )}
    </div>
  )
}