import axios from 'axios';
import { formatErrorResponse } from '../utils/formatErrors';

export const createSelfCheckoutUser = async (data) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.post('/FrontierUser/SelfCheckout', data, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }

}

export const updateSelfCheckoutUser = async (data) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.put('/FrontierUser/SelfCheckout', data, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const validateSelfCheckoutUser = async (data) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.post('/FrontierUser/Validate/SelfCheckout', data, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const getSelfCheckoutUsersList = async () => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.get('/FrontierUser/SelfCheckout', config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const resetUserIdAndPassword = async (email) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.put(`/FrontierUser/ResetUser/SelfCheckout/${email}`, {}, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const createUserAttribute = async (data) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.post('/FrontierUserAttribute', data, config);
    return response.data.result;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const updateUserAttribute = async (data) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.put('/FrontierUserAttribute', data, config);
    return response.data.result;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}