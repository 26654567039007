import { VALIDATION_TYPES } from "../utils/constants";

export const editChildPhysicalCountForm = [
    {
        "name": "identifier",
        "label": "SKU",
        "value": "PEN",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "PEN"
    },
    {
        "name": "count",
        "label": "Count",
        "value": "1",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "1"
    },
    {
        "name": "location",
        "label": "Location",
        "value": "MAIN",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "MAIN"
    }
]

export const editParentPhysicalCountForm = [
    {
        "name": "identifier",
        "label": "SKU",
        "value": "PEN",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "PEN"
    },
    {
        "name": "count",
        "label": "Count",
        "value": "1",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "1"
    },
    {
        "name": "location",
        "label": "Location",
        "value": "MAIN",
        "type": "select",
        "selectName": "Select Location",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": "MAIN"
    }
]

export const createPhysicalCountForm = [
    {
        "name": "sku",
        "label": "SKU",
        "value": "PEN",
        "type": "autocomplete",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": "PEN"
    },
    {
        "name": "count",
        "label": "Count",
        "value": "1",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "1"
    },
    {
        "name": "locationId",
        "label": "Location",
        "value": "MAIN",
        "type": "select",
        "selectName": "Select Location",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": "cksystem"
    }
]

export const detailPhysicalCountForm = [
    {
        "name": "sku",
        "label": "SKU",
        "value": "PEN",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "PEN"
    },
    {
        "name": "count",
        "label": "Count",
        "value": "1",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "1"
    },
    {
        "name": "location",
        "label": "Location",
        "value": "MAIN",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "MAIN"
    },
    {
        "name": "creationDate",
        "label": "Creation Date",
        "value": "",
        "type": "dateTime",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": ""
    }
]

export const productForm = [
    {
        "name": "sku",
        "label": "Sku",
        "value": "sku",
        "isAutoFocus": true,
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "sku"
    },
    {
        "name": "name",
        "label": "Name",
        "value": "product name",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "Product Name"
    },
    {
        "name": "minimumAgeRequirement",
        "label": "Minimum Age Requirement",
        "value": "",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": false,
        "placeholder": ""
    }
]

export const detailProductForm = [
    {
        "name": "sku",
        "label": "Sku",
        "value": "sku",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "sku"
    },
    {
        "name": "name",
        "label": "Name",
        "value": "product name",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "Product Name"
    },
    {
        "name": "minimumAgeRequirement",
        "label": "Minimum Age Requirement",
        "value": "",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": ""
    }
]

export const applicationForm = [
    {
        "name": "applicationId",
        "label": "Application Name",
        "value": "WebConnector",
        "isAutoFocus": true,
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "WebConnector"
    },
    {
        "name": "serialNumberPrefix",
        "label": "Serial Number Prefix",
        "value": "LE-",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "W",
        "maxLength": 5
    },
    {
        "name": "applicationDescription",
        "label": "Application Description",
        "value": "Connect to the Web",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "Sync with web stores",
        "maxLength": 1000
    },
    {
        "name": "serialNumberSuffix",
        "label": "Serial Number Suffix",
        "value": new Date(),
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "R",
        "maxLength": 5
    },
    {
        "name": "maxKeyAge",
        "label": "Max Key Age",
        "value": "25",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "90"
    },
    {
        "name": "isActive",
        "label": "Status",
        "value": "Active",
        "type": "isActive",
        "validation": VALIDATION_TYPES.EMAIL,
        "maxLength": 100,
        "required": true,
        "permissions": ["Manager", "Point of Sale"]
    },
    {
        "name": "hasUserCount",
        "label": "Has User Count",
        "value": "",
        "type": "isActive",
        "validation": VALIDATION_TYPES.NONE,
        "required": true
    },
    {
        "name": "requireAPIKey",
        "label": "Require API Key",
        "value": "Active",
        "type": "isActive",
        "validation": VALIDATION_TYPES.NONE,
        "maxLength": 100,
        "required": true,
        "permissions": ["Manager", "Point of Sale"]
    }
]

export const detailApplicationForm = [
    {
        "name": "applicationId",
        "label": "Application Name",
        "value": "WebConnector",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "WebConnector"
    },
    {
        "name": "serialNumberPrefix",
        "label": "Serial Number Prefix",
        "value": "LE-",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "W",
        "maxLength": 5
    },
    {
        "name": "applicationDescription",
        "label": "Application Description",
        "value": "Connect to the Web",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "Sync with web stores",
        "maxLength": 1000
    },
    {
        "name": "serialNumberSuffix",
        "label": "Serial Number Suffix",
        "value": "",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "R",
        "maxLength": 5
    },
    {
        "name": "maxKeyAge",
        "label": "Max Key Age",
        "value": "25",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "90"
    },
    {
        "name": "isActive",
        "label": "Status",
        "value": "Active",
        "type": "immutableBool",
        "validation": VALIDATION_TYPES.EMAIL,
        "maxLength": 100,
        "required": true,
        "permissions": ["Manager", "Point of Sale"]
    },
    {
        "name": "lastSerialNumberIssued",
        "label": "Last Serial Number",
        "value": "",
        "type": "immutable",
        "validation": VALIDATION_TYPES.EMAIL,
        "maxLength": 100,
        "required": true,
        "permissions": ["Manager", "Point of Sale"]
    },
    {
        "name": "hasUserCount",
        "label": "Has User Count",
        "value": "",
        "type": "immutableBool",
        "validation": VALIDATION_TYPES.NONE,
        "required": true
    },
    {
        "name": "requireAPIKey",
        "label": "Require API Key",
        "value": "Active",
        "type": "immutableBool",
        "validation": VALIDATION_TYPES.NONE,
        "maxLength": 100,
        "required": true,
        "permissions": ["Manager", "Point of Sale"]
    }
]

export const applicationOptionForm = [
    {
        "name": "optionId",
        "label": "Option Id",
        "value": "Dashboard",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "ShippingTNT"
    },
    {
        "name": "optionName",
        "label": "Option Name",
        "value": "LE-",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "Shipping TNT",
    },
    {
        "name": "optionDescription",
        "label": "Option Description",
        "value": "Connect to the Web",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "Shipping through TNT",
    },
    {
        "name": "isActive",
        "label": "Status",
        "value": "Active",
        "type": "isActive",
        "validation": VALIDATION_TYPES.EMAIL,
        "maxLength": 100,
        "required": true,
    }
]

export const companyLabels = {
    "sqlServerId": "SQL Server Id",
    "sqlDatabaseName": "SQL Database Name",
}

export const companiesForm = [
    {
        "name": "companyId",
        "label": "Company Id",
        "isAutoFocus": true,
        "value": "cksystem",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "cksystem"
    },
    {
        "name": "sqlDatabaseName",
        "label": "SQL Database Name",
        "value": "FrontierFX-cksystem",
        "type": "text",
        "validation": VALIDATION_TYPES.EMAIL,
        "required": true,
        "placeholder": "FrontierFX-cksystem"
    },
    {
        "name": "name",
        "label": "Company Name",
        "value": "C&K Systems",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "C&K Systems"
    },
    {
        "name": "sqlServerId",
        "label": "SQL Server Id",
        "value": "SQL-1",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "SQL-1"
    },
    {
        "name": "url",
        "label": "URL",
        "value": "http://www.cksystems.com",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "http://www.cksystems.com",
    },
    {
        "name": "isActive",
        "label": "Status",
        "value": "true",
        "type": "isActive",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
    }
]

export const detailCompaniesForm = [
    {
        "name": "companyId",
        "label": "Company Id",
        "value": "cksystem",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "cksystem"
    },
    {
        "name": "sqlDatabaseName",
        "label": "SQL Database Name",
        "value": "FrontierFX-cksystem",
        "type": "immutable",
        "validation": VALIDATION_TYPES.EMAIL,
        "required": true,
        "placeholder": "FrontierFX-cksystem"
    },
    {
        "name": "name",
        "label": "Company Name",
        "value": "C&K Systems",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "C&K Systems"
    },
    {
        "name": "sqlServerId",
        "label": "SQL Server Id",
        "value": "SQL-1",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "SQL-1"
    },
    {
        "name": "url",
        "label": "URL",
        "value": "http://www.cksystems.com",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "http://www.cksystems.com",
    },
    {
        "name": "isActive",
        "label": "Status",
        "value": "true",
        "type": "immutableBool",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
    }
]

export const createLicensesForm = [
    {
        "name": "companyIdName",
        "label": "Company Name",
        "value": "cksystem",
        "type": "select",
        "selectName": "Select Company",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": "cksystem"
    },
    {
        "name": "isActive",
        "label": "Status",
        "value": "Active",
        "type": "isActive",
        "validation": VALIDATION_TYPES.NONE,
        "required": true
    },
    {
        "name": "applicationIdName",
        "label": "Application Name",
        "value": "WebConnector",
        "type": "select",
        "selectName": "Select Application",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": "WebConnector"
    },
    {
        "name": "notes",
        "label": "Notes",
        "value": "web connector",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "web connector "
    },
    {
        "name": "expirationDate",
        "label": "Expiration Date",
        "value": Date.now(),
        "type": "datepicker",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": Date.now()
    },
    {
        "name": "userCount",
        "label": "User Count",
        "value": 0,
        "type": "select",
        "selectName": "Unlimited",
        "canShowField": true,
        "validation": VALIDATION_TYPES.NONE,
        "placeholder": 5,
        "options": ["1", "5", "10", "25", "50", "100"]
    }
]

export const editLicensesForm = [
    {
        "name": "companyIdName",
        "label": "Company Name",
        "value": "cksystem",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": "cksystem"
    },
    {
        "name": "isUpdateRequired",
        "label": "Force  Update",
        "value": "Active",
        "type": "isActive",
        "useBoolText": true,
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
    },
    {
        "name": "applicationIdName",
        "label": "Application Name",
        "value": "WebConnector",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": "WebConnector"
    },
    {
        "name": "expirationDate",
        "label": "Expiration Date",
        "value": "expirationDate",
        "type": "datepicker",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": Date.now()

    },
    {
        "name": "isActive",
        "label": "Status",
        "value": "Active",
        "type": "isActive",
        "validation": VALIDATION_TYPES.NONE,
        "required": true
    },
    {
        "name": "notes",
        "label": "Notes",
        "value": "web connector",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "web connector "
    },
    {
        "name": "userCount",
        "label": "User Count",
        "value": 0,
        "type": "select",
        "selectName": "Unlimited",
        "canShowField": true,
        "validation": VALIDATION_TYPES.NONE,
        "placeholder": 5,
        "options": ["1", "5", "10", "25", "50", "100"]
    }
]

export const detailLicensesForm = [
    {
        "name": "companyIdName",
        "label": "Company Name",
        "value": "cksystem",
        "type": "immutable",
        "selectName": "Select Company",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": "cksystem"
    },
    {
        "name": "isActive",
        "label": "Is Active",
        "value": "Active",
        "type": "immutableBool",
        "validation": VALIDATION_TYPES.NONE,
        "required": true
    },
    {
        "name": "applicationIdName",
        "label": "Application Name",
        "value": "WebConnector",
        "type": "immutable",
        "selectName": "Select Application",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": "WebConnector"
    },
    {
        "name": "expirationDate",
        "label": "Expiration Date",
        "value": Date.now(),
        "type": "dateTime",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": Date.now()
    },
    {
        "name": "notes",
        "label": "Notes",
        "value": "web connector",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "web connector "
    },
    {
        "name": "isUpdateRequired",
        "label": "Force  Update",
        "value": "Active",
        "type": "immutableBool",
        "useBoolText": true,
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
    },
    {
        "name": "serialNumber",
        "label": "Serial Number",
        "value": "",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "R00000000W"
    },
    {
        "name": "status",
        "label": "Status",
        "value": "",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": ""
    },
    {
        "name": "lastContact",
        "label": "Last Contact",
        "value": "",
        "type": "dateTime",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": ""
    },
    {
        "name": "initializationDate",
        "label": "Creation Date",
        "value": "",
        "type": "dateTime",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": ""
    },
    {
        "name": "userCount",
        "label": "User Count",
        "value": 0,
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true
    }
]

export const editLicenseOptionForm = [
    {
        "name": "isRegistered",
        "label": "Is Registered",
        "value": "Active",
        "type": "isActive",
        "validation": VALIDATION_TYPES.EMAIL,
        "maxLength": 100,
        "required": true,
    },
    {
        "name": "isActive",
        "label": "Status",
        "value": "Active",
        "type": "isActive",
        "validation": VALIDATION_TYPES.EMAIL,
        "maxLength": 100,
        "required": true,
    }
]

export const createLicenseOptionForm = [
    {
        "name": "optionIdName",
        "label": "Application Option Name",
        "value": "ShippingTNT",
        "type": "select",
        "selectName": "Select Option",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
    },
    {
        "name": "isActive",
        "label": "Status",
        "value": "Active",
        "type": "isActive",
        "validation": VALIDATION_TYPES.EMAIL,
        "maxLength": 100,
        "required": true,
    },
    {
        "name": "isRegistered",
        "label": "Is Registered",
        "value": "Active",
        "type": "isActive",
        "validation": VALIDATION_TYPES.EMAIL,
        "maxLength": 100,
        "required": true,
    }
]

export const createUserForm = [
    {
        "name": "firstName",
        "label": "First Name",
        "isAutoFocus": true,
        "value": "",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "Fred"
    },
    {
        "name": "companyIds",
        "label": "Company Name",
        "value": "cksystem",
        "type": "multiautocomplete",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": "cksystem"
    },
    {
        "name": "lastName",
        "label": "Last Name",
        "value": "",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "Flinstone"
    },
    {
        "name": "phoneNumber",
        "label": "Phone Number",
        "value": "",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": false,
        "placeholder": "555-555-5555"
    },
    {
        "name": "email",
        "label": "Email",
        "value": "",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "fflinstone@bedrock.com"
    },
    {
        "name": "isActive",
        "label": "Status",
        "value": "Active",
        "type": "isActive",
        "validation": VALIDATION_TYPES.NONE,
        "required": true
    }
]

export const editUserForm = [
    {
        "name": "firstName",
        "label": "First Name",
        "value": "",
        "isAutoFocus": true,
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "Fred"
    },
    {
        "name": "companyIds",
        "label": "Company Name",
        "value": "cksystem",
        "type": "multiautocomplete",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": "cksystem"
    },
    {
        "name": "lastName",
        "label": "Last Name",
        "value": "",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "Flinstone"
    },
    {
        "name": "phoneNumber",
        "label": "Phone Number",
        "value": "",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": false,
        "placeholder": "555-555-5555"
    },
    {
        "name": "email",
        "label": "Email",
        "value": "",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "fflinstone@bedrock.com"
    },
    {
        "name": "isActive",
        "label": "Status",
        "value": "Active",
        "type": "isActive",
        "validation": VALIDATION_TYPES.NONE,
        "required": true
    }
]

export const detailUserForm = [
    {
        "name": "firstName",
        "label": "First Name",
        "value": "",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "Fred"
    },
    {
        "name": "companyIds",
        "label": "Company Name",
        "value": "cksystem",
        "type": "immutable",
        "selectName": "Select Company",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": "cksystem"
    },
    {
        "name": "lastName",
        "label": "Last Name",
        "value": "",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "Flinstone"
    },
    {
        "name": "phoneNumber",
        "label": "Phone Number",
        "value": "",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": false,
        "placeholder": "555-555-5555"
    },
    {
        "name": "email",
        "label": "Email",
        "value": "",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "fflinstone@bedrock.com"
    },
    {
        "name": "isActive",
        "label": "Status",
        "value": "Active",
        "type": "immutableBool",
        "validation": VALIDATION_TYPES.NONE,
        "required": true
    }
]


export const assignDeviceRegistrationsForm = [
    {
        "name": "deviceToken",
        "label": "Device Token",
        "value": "",
        "type": "text",
        "isAutoFocus": true,
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "ABCDEF"
    },
    {
        "name": "companyIdName",
        "label": "Company Name",
        "value": "cksystem",
        "type": "select",
        "selectName": "Select Company",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": "cksystem"
    },
    {
        "name": "deviceName",
        "label": "Device Name",
        "value": "web connector",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "warehouse"
    }
]

export const endUserAssignDeviceRegistrationsForm = [
    {
        "name": "deviceToken",
        "label": "Device Token",
        "value": "",
        "type": "text",
        "isAutoFocus": true,
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "ABCDEF"
    },
    {
        "name": "deviceName",
        "label": "Device Name",
        "value": "web connector",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "warehouse"
    }
]

export const editDeviceRegistrationsForm = [
    {
        "name": "companyIdName",
        "label": "Company Name",
        "value": "cksystem",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": "cksystem"
    },
    {
        "name": "isActive",
        "label": "Status",
        "value": "Active",
        "type": "isActive",
        "validation": VALIDATION_TYPES.NONE,
        "required": true
    },
    {
        "name": "deviceId",
        "label": "Device Id",
        "value": "",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": ""
    },
    {
        "name": "deviceName",
        "label": "Device name",
        "value": "",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "device name"
    }
]

export const endUserEditDeviceRegistrationsForm = [
    {
        "name": "companyIdName",
        "label": "Company Name",
        "value": "cksystem",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": "cksystem"
    },
    {
        "name": "deviceName",
        "label": "Device name",
        "value": "",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "device name"
    }
]

export const detailDeviceRegistrationsForm = [
    {
        "name": "deviceToken",
        "label": "Device Token",
        "value": "",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "ABCDEF"
    },
    {
        "name": "companyIdName",
        "label": "Company Name",
        "value": "cksystem",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "cksystem"
    },
    {
        "name": "deviceName",
        "label": "Device Name",
        "value": "web connector",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "warehouse"
    },
    {
        "name": "isActive",
        "label": "Status",
        "value": "",
        "type": "immutableBool",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "warehouse"
    }
]

export const endUserDetailDeviceRegistrationsForm = [
    {
        "name": "deviceToken",
        "label": "Device Token",
        "value": "",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "ABCDEF"
    },
    {
        "name": "companyIdName",
        "label": "Company Name",
        "value": "cksystem",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "cksystem"
    },
    {
        "name": "deviceName",
        "label": "Device Name",
        "value": "web connector",
        "type": "immutable",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "placeholder": "warehouse"
    }
]

export const selfCheckoutUserForm = [
    {
        "name": "email",
        "label": "Email",
        "value": "",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": ""
    },
    {
        "name": "name",
        "label": "Name",
        "value": "",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": ""
    }
]

export const locationForm = [
    {
        "name": "locationId",
        "label": "Location Id",
        "value": "",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": ""
    },
    {
        "name": "description",
        "label": "Description",
        "value": "",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": ""
    },
    {
        "name": "profitCenter",
        "label": "Profit Center",
        "value": "",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": ""
    },
    {
        "name": "regionId",
        "label": "Region Id",
        "value": "",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": ""
    },
    {
        "name": "isValidStocking",
        "label": "Is Valid Stocking",
        "value": "",
        "type": "isActive",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": ""
    },
    {
        "name": "isValidSelling",
        "label": "Is Valid Selling",
        "value": "",
        "type": "isActive",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": ""
    },
    {
        "name": "isValidPurchasing",
        "label": "Is Valid Purchasing",
        "value": "",
        "type": "isActive",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": ""
    },
    {
        "name": "isActive",
        "label": "Is Active",
        "value": "",
        "type": "isActive",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": ""
    },
]

export const stationForm = [
    {
        "name": "name",
        "label": "Name",
        "value": "",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": ""
    },
    {
        "name": "description",
        "label": "Description",
        "value": "",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": ""
    },
    {
        "name": "locationIdName",
        "label": "Location Name",
        "value": "",
        "type": "select",
        "selectName": "Select Location",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": "cksystem"
    },
    {
        "name": "isEnabled",
        "label": "Is Enabled",
        "value": "",
        "type": "isActive",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": ""
    },
    {
        "name": "nodeId",
        "label": "Ticket Prefix",
        "value": "",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": ""
    },
    {
        "name": "ticketNumberSuffix",
        "label": "Ticket Suffix",
        "value": "",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": ""
    }
]

export const attributeForm = [
    {
        "name": "name",
        "label": "Name",
        "value": "",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": ""
    },
    {
        "name": "value",
        "label": "Value",
        "value": "",
        "type": "text",
        "validation": VALIDATION_TYPES.NONE,
        "required": true,
        "options": [],
        "placeholder": ""
    }
]