import React, { useState, useEffect } from "react";
import IsActiveToggle from "./IsActiveToggle";
import { formatDateTime } from "../utils/dateTime"
import Loader from "../components/Loader";
import PropTypes from 'prop-types';
import MobileCard from "./MobileCard";

const mapColumns = (legendMap) => {
  const columns = Object.values(legendMap);
  return columns;
}

export default function DynamicTable(props) {
  const [showOptions, setShowOptions] = useState([]);
  const [activeData, setActiveData] = useState([]);
  const [animateClipboard, setAnimateClipboard] = useState([]);
  const [clipboardText, setClipboardText] = useState("");
  const columns = mapColumns(props.legend.dataMap);
  const rows = Object.keys(props.legend.dataMap);
  let childColumns = [];
  let childRows = [];
  if (props.legend.childMap && Object.keys(props.legend.childMap).length > 0) {
    childColumns = mapColumns(props.legend.childMap);
    childRows = Object.keys(props.legend.childMap);
  }

  useEffect(() => {
    if (showOptions.length !== props.rowData.length) {
      const show = Array(props.rowData.length).fill(false);
      setShowOptions(show);
    }
    let rowData = props.filtering ? props.filteredData : props.rowData;
    if (animateClipboard.length === 0) {
      let animateClipboardFalse = new Array(rowData.length).fill(false);
      setAnimateClipboard(animateClipboardFalse);
    }

    setActiveData(rowData);
  });

  const headerData = () => {
    return columns.map((columnKey) => {
      return <th key={columnKey.column} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 key={data}">{columnKey.column}</th>
    })
  }

  const optionsHasTrue = (element) => {
    return element;
  }

  const rowOpacityColorStyling = (dataIdx) => {
    let style = dataIdx % 2 == 0 ? "hover:bg-green-100 cursor-pointer" : "hover:bg-green-100 cursor-pointer bg-green-50";
    if (showOptions.some(optionsHasTrue)) {
      let opacity = showOptions[dataIdx] ? style : style + " opacity-80";
      return opacity;
    } else {
      return style;
    }
  }

  const copyClipboard = (e, text, rowIdx) => {
    e.stopPropagation();
    let animateClipboardOn = [...animateClipboard];
    animateClipboardOn[rowIdx] = true;
    setAnimateClipboard(animateClipboardOn);
    setClipboardText(`Copied ${text} to clipboard`);

    navigator.clipboard.writeText(text);
    let animateClipboardOff = [...animateClipboardOn];
    animateClipboardOff[rowIdx] = false;
    setTimeout(() => setAnimateClipboard(animateClipboardOff), 500)
    setTimeout(() => setClipboardText(""), 3000)
  }

  const createRow = (data, dataIdx) => {
    return (
      
        <tr
          onClick={(e) => props.openDetailRowModal(e, data)}
          className={rowOpacityColorStyling(dataIdx)}
        >
            {rows.map((row) => {
              let value = data[row];

              const rowKey = props.legend.dataMap[row];
              switch(rowKey.type) {
                case "toggle":
                  return (
                    <td>
                      <p onClick={(e) => e.stopPropagation()}>
                        <IsActiveToggle
                          active={value}
                          update={props.activateDeactivateRow}
                          data={data}
                        />
                      </p>
                    </td>
                  )
                case "reset":
                  return (
                    <td className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" onClick={(e) => props.openResetModal(e, data)}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-500 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                      </svg>
                    </td>
                  )
                case "dateTime":
                  return <td className="px-3 py-4 text-sm text-gray-700">{formatDateTime(value)}</td>
                case "bool":
                  return <td className="px-3 py-4 text-sm text-gray-700">{value ? "True" : "False"}</td>
                case "money":
                  return <td className="px-3 py-4 text-sm text-gray-700">{USDollar.format(value)}</td>
                default:
                  return <td className="px-3 py-4 text-sm text-gray-700 mx-auto items-center justify-center">
                      {value}
                      {rowKey.hasClipboard && (
                        <div className="flex items-center justify-center">
                          <button
                            className={`${animateClipboard[dataIdx] == true && "animate-bounce"} cursor-grab`}
                            title="copy"
                            onClick={(e) => copyClipboard(e, value, dataIdx)}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
                            </svg>
                          </button>
                        </div>
                      )}
                    </td>
              }
            }
          )}
          {!props.removeRowEdit && <td
            className="px-3 py-4 text-sm text-indigo-700 hover:text-indigo-900 font-semibold"
            onClick={(e) => props.openEditRowModal(e, data)}
          >
              Edit
          </td>}
          {expandOptions(data, dataIdx)}
        </tr>
      )
  }

  const fillChildBlankRows = (rowsLength, addOption = true, addEdit = true) => {
    let parentLength = columns.length; 
    parentLength += 1; // for Edit
    if (props.hasChildData) {
      parentLength += 1; // for Options
    }
    let childLength = rowsLength;
    if (addOption) {
      childLength += 1; // add option
    }
    if (addEdit) {
      childLength += 1; // edit
    }
    const remainingRows = parentLength - childLength;
    if (remainingRows > 0){
      const remainingRowsArray = Array(remainingRows).fill("");
      return remainingRowsArray.map((idx) => {
        return (
          <td key={idx} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></td>
        )
      })
    }
    
  }

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const createChildRow = (child, childIdx, dataIdx) => {
    if (showOptions[dataIdx]) {
      return (
        <tr className={childIdx % 2 == 0 ? "bg-indigo-100" : null}>
          <td scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></td>
          {childRows.map((childField) => {
            const childKey = props.legend.childMap[childField];
            if (childKey.type === 'toggle') {
              return (
                <td key={childIdx} onClick={(e) => e.stopPropagation()}>
                  <IsActiveToggle
                    active={child.isActive}
                    update={props.activateDeactivateChild}
                    data={child}
                  />
                </td>
              )
            } else if (childKey.type === "bool") {
                return <td key={childIdx} className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">{childField ? "True" : "False"}</td>
            } else if (childKey.type === "parent") {
              return (
                <td key={childIdx} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{props.getParentValue(child, childField)}</td>
              )
            } else if (childKey.type === "money") {
              return (
                <td key={childIdx} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{USDollar.format(child[childField])}</td>
              )
            } else {
              return (
                <td key={childIdx} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{child[childField]}</td>
              )
            }
          })}
          {!props.removeChildEdit && (
            <td
              className="cursor-pointer text-left whitespace-nowrap text-sm text-indigo-700 hover:text-indigo-900 font-semibold"
              onClick={() => props.openEditChildModal(child)}
            >
                Edit
            </td>
          )}
          {props.allowDeleteChild && (
            <td onClick={(e) => props.openDeleteModal(e, child)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
              </svg>

            </td>
          )}
          {fillChildBlankRows(childRows.length, true, !props.removeChildEdit)}
        </tr>
      )
    }
  }

  const updateOptions = (e, dataIdx) => {
    e.stopPropagation();
    setShowOptions(showOptions => {
      let nextState = [...showOptions];
      nextState = nextState.map((tableRow, tableIdx) => {
        if (tableIdx === dataIdx) {
          return !tableRow
        }
        return false;
      })
      return nextState;
    }) 
  }

  const expandOptions = (data, dataIdx) => {
    if (!props.allowExpandOptions && props.parentHasOptions !== undefined && !props.parentHasOptions(data)) {
      return <td></td>
    }
    if (props.hasChildData) {
      return (
        <td>
          <button
            type="button"
            className="h-6 inline-flex items-center justify-center rounded-md border border-transparent m-4 bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            onClick={(e) => updateOptions(e, dataIdx)}
          >
            {props.childTitle ? props.childTitle : "Options"}
          </button>
        </td>
      )
    } else {
      return <td></td>
    }
  }

  const createChildHeader = (data, dataIdx, hasChildData) => {
    if (showOptions[dataIdx]) {
      if (!hasChildData) {
        return (
          <tr className="bg-gray-50 border rounded">
            {props.openCreateChildModal && <td scope="col" className="text-sm text-gray-900">
              <button
                type="button"
                className="rounded-md border border-transparent m-2 bg-indigo-600 p-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                onClick={() => props.openCreateChildModal(data.id)}
              >
                  {props.childTitle ? (props.childTitle) : "Add Option"}
              </button>
              </td>}
              <td><p className="border-2 border-dotted border-indigo-200 font-semibold text-gray-400 px-2">No data</p></td>
              {fillChildBlankRows(2, false, false)}
          </tr>

        )
      } else {
        return (
          <tr className="bg-gray-50 border rounded">
            <th scope="col" className="text-sm text-gray-900">
              {props.openCreateChildModal && <button
                type="button"
                className="h-6 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                onClick={() => props.openCreateChildModal(data.id)}
              >
                  {props.childTitle ? (props.childTitle) : "Add Option"}
              </button>}
            </th>
            {childColumns.map((headerKey) => {
              return (
                  <th key={headerKey.column} scope="col" className="px-3 py-3.5 text-left text-sm  text-gray-900">
                    <div className="flex flex-row">
                      {headerKey.column}
                      {headerKey.isSortable && (
                        <button onClick={() => props.sortByField(dataIdx)}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-4 w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </th>

              )
            })}
            {fillChildBlankRows(childColumns.length, false)}
          </tr>
        )
      }
    }
  }

  const rowData = () => {
    return activeData.map((data, dataIdx) => {
      let rows = [];
      let row = createRow(data, dataIdx);
      rows.push(row);
      if (props.hasChildData) {
        const childRows = data[props.legend.childField];
        if (childRows.length > 0) {
          rows.push(createChildHeader(data, dataIdx, true));
        } else {
          rows.push(createChildHeader(data, dataIdx, false));

        }
        childRows.map((childData, childDataIdx) => {
          rows.push(createChildRow(childData, childDataIdx, dataIdx))
        })
      }
      return rows;
    })
  }

  if (props.loading) {
    return (
      <Loader />
    )
  } else if (props.rowData.length === 0) {
    return (
        <div className="mt-4 flex flex-col">
          <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="flex items-center justify-center border-4 border-dashed border-gray-200 rounded-lg h-96">
                <p className="font-bold text-gray-600 text-xl">{props.noDataMessage}</p>
              </div>
              </div>
            </div>
          </div>
    )
    } else {
      return (
        <div>
          <div className="mt-4 hidden md:block -my-2 -mx-4 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                {clipboardText.length > 0 && (
                  <div id="authentication-modal" tabIndex="-1" aria-hidden="true" className="overflow-y-auto overflow-x-hidden fixed top-1/2 left-1/2 z-50 w-full mt-20 md:inset-0 h-modal md:h-full">
                    <p className="border-4 border-dashed border-indigo-300 p-2 absolute left-1/3 top-1/4 text-sm font-medium">{clipboardText}</p>
                  </div>
                )}
                  <table className="w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                          <tr>{headerData()}
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th> {/* blank column for edit */}
                            {props.hasChildData && (
                              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
                            )}
                          </tr>
                      </thead>
                      <tbody>
                        {rowData()}
                      </tbody>
                  </table>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 md:hidden mt-4">
            {activeData.map((dataRecord) => {
              return (
                <MobileCard 
                  legend={props.mobileLegend.dataMap}
                  keys={Object.keys(props.mobileLegend.dataMap)}
                  childKeys={Object.keys(props.mobileLegend.childMap)}
                  childLegend={props.mobileLegend.childMap}
                  childField={props.mobileLegend.childField}
                  getParentValue={props.getParentValue}
                  resetSubmit={props.openResetModal}
                  data={dataRecord}
                  key={dataRecord.id}
                  openEditRowModal={props.openEditRowModal}
                  openDetailRowModal={props.openDetailRowModal}
                />
              )
            })}
          </div>
        </div>
      )
  }
}

DynamicTable.propTypes = {
    rowData: PropTypes.array,
    legend: PropTypes.object,
    mobileLegend: PropTypes.object,
    loading: PropTypes.bool,
    hasChildData: PropTypes.bool,
    openCreateChildModal: PropTypes.func,
    openEditChildModal: PropTypes.func,
    activateDeactivateChild: PropTypes.func,
    activateDeactivateRow: PropTypes.func,
    openEditRowModal: PropTypes.func,
    openDetailRowModal: PropTypes.func,
    openResetModal: PropTypes.func,
    openDeleteModal: PropTypes.func,
    allowDeleteChild: PropTypes.bool,
    getParentValue: PropTypes.func,
    removeChildEdit: PropTypes.bool,
    removeRowEdit: PropTypes.bool,
    filteredData: PropTypes.arraay,
    filtering: PropTypes.bool,
    noDataMessage: PropTypes.string,
    childTitle: PropTypes.string,
    sortByField: PropTypes.func,
    parentHasOptions: PropTypes.func,
    allowExpandOptions: PropTypes.bool,
}