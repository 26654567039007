import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  BsChevronDown
} from 'react-icons/bs';
import PropTypes from 'prop-types';

export default function SideNavigation(props) {
  const [cloud9AdministrationOpen, setCloud9AdministrationOpen] = useState(false);
  const [locationsOpen, setLocationsOpen] = useState(false);
  const [pointOfSaleOpen, setPointOfSaleOpen] = useState(false);
  const [systemOpen, setSystemOpen] = useState(false);

  const closedClass = "flex mt-12"
  const openClass = "flex mt-12"

  const SidebarDropdown = (e, settingClickEvent) => {
    e.preventDefault();
    settingClickEvent;
  }

  return (
    <div className={props.closed ? openClass : closedClass}>
      <div className="flex-1 flex flex-col min-h-0">
        <div className="flex-1 flex flex-col pt-5 pb-4">
          <div className="flex items-center flex-shrink-0 px-4">
          </div>
          <nav className="mt-5 flex-1 px-2 space-y-1">
            <ul className="list-none">
              <li>
                <Link to="/frontier-users" className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                  <svg className="text-gray-500 mr-3 flex-shrink-0 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                  </svg>
                  Cloud9 Administration
                  <div className="ml-4">
                    <BsChevronDown
                      onClick={(e) => SidebarDropdown(e, setCloud9AdministrationOpen(!cloud9AdministrationOpen))}
                      className={`${cloud9AdministrationOpen && 'rotate-180'}`}
                      size="1.15em"
                    />

                  </div>
                </Link>
                {cloud9AdministrationOpen && (
                  <>
                    <Link to="/users">
                      <ul className="ml-4 text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md">Users</ul>
                    </Link>
                    <Link to="/companies">
                      <ul className="ml-4 text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md">Companies</ul>
                    </Link>
                    <Link to="/applications">
                      <ul className="ml-4 text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md">Applications</ul>
                    </Link>
                    <Link to="/licenses">
                      <ul className="ml-4 text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md">Licenses</ul>
                    </Link>
                  </>
                )}
                <Link to="/locations" className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                  <svg className="text-gray-500 mr-3 flex-shrink-0 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                  </svg>
                  Locations
                  <div className="ml-4">
                    <BsChevronDown
                      onClick={(e) => SidebarDropdown(e, setLocationsOpen(!locationsOpen))}
                      className={`${locationsOpen && 'rotate-180'}`}
                      size="1.15em"
                    />

                  </div>
                </Link>
                {locationsOpen && (
                  <Link to="/stations">
                    <ul className="ml-4 text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md">Stations</ul>
                  </Link>
                )}
                <li>
                  <Link to="/products" className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-gray-400 group-hover:-text-gray-500 flex-shrink-0 mr-3 h-6 w-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                    </svg>
                    Products
                  </Link>
                </li>
                <li>
                  <Link to="/physical-counts" className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-gray-400 group-hover:-text-gray-500 flex-shrink-0 mr-3 h-6 w-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M7.875 14.25l1.214 1.942a2.25 2.25 0 001.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 011.872 1.002l.164.246a2.25 2.25 0 001.872 1.002h2.092a2.25 2.25 0 001.872-1.002l.164-.246A2.25 2.25 0 0116.954 9h4.636M2.41 9a2.25 2.25 0 00-.16.832V12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 01.382-.632l3.285-3.832a2.25 2.25 0 011.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0021.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 002.25 2.25z" />
                    </svg>
                    Asset Snapshot
                  </Link>
                </li>
                <div className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                  <svg className="text-gray-500 mr-3 flex-shrink-0 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                  </svg>
                  Point of Sale
                  <div className="ml-4 cursor-pointer">
                    <BsChevronDown
                      onClick={() => setPointOfSaleOpen(!pointOfSaleOpen)}
                      className={`${pointOfSaleOpen && 'rotate-180'}`}
                      size="1.15em"
                    />
                  </div>
                </div>
                {pointOfSaleOpen && (
                  <>
                    <Link to="/tickets">
                      <ul className="ml-4 text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md">Transactions</ul>
                    </Link>
                    <Link to="/frontier-users">
                      <ul className="ml-4 text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md">PartnerTechSCO</ul>
                    </Link>
                  </>
                )}
                  <div className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                    <svg className="text-gray-500 mr-3 flex-shrink-0 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                    </svg>
                    System
                    <div className="ml-4 cursor-pointer">
                      <BsChevronDown
                        onClick={() => setSystemOpen(!systemOpen)}
                        className={`${systemOpen && 'rotate-180'}`}
                        size="1.15em"
                      />

                    </div>
                  </div>
                  {systemOpen && (
                    <>
                    <Link to="/users" className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center text-sm font-medium rounded-md">
                      <ul className="ml-4 text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md">Users</ul>
                    </Link>
                    <Link to="/device-registrations" className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center text-sm font-medium rounded-md">
                      <ul className="ml-4 text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md">Devices</ul>
                    </Link>
                  </>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}

SideNavigation.propTypes = {
  closed: PropTypes.bool,
  handleClick: PropTypes.func,
  hasViewUserPermissions: PropTypes.bool
}