import React, { useState, useEffect } from "react";
import { getProductList, updateProduct } from "../services/Products";
import CreateEditModal from "../components/CreateEditModal";
import { productsKey, mobileProductsKey } from "../utils/labelMap";
import { productForm, detailProductForm } from "../utils/forms";
import DynamicTable from "../components/DynamicTable";

export default function Products() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingProduct, setEditingProduct] = useState(false);
  const [viewingProductDetail, setViewingProductDetail] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    async function fetchData() {
        setLoading(true);
        const data = await getProductList();
        if (data.length > 0) {
          setProducts(data);
        }
        setLoading(false);
    }
    fetchData();
  }, []);

  const openProductDetailModal = (e, data) => {
    e.stopPropagation(); // stops registration options from rendering when Row is clicked
    setViewingProductDetail(true);
    setSelectedData(data);
  }

  const closeModal = () => {
    setEditingProduct(false);
    setViewingProductDetail(false);
    setErrorMessage('');
  }

  const handleChange = e => {
    let { name, value } = e.target;
    setSelectedData(selectedData => ({
      ...selectedData,
      [name]: value
    }));
  };

  const handleBool = (name, boolValue) => {
    setSelectedData(selectedData => ({
      ...selectedData,
      [name]: boolValue
    }));
  };

  const updateProductSubmit = async () => {
    setErrorMessage('');
    const response = await updateProduct(selectedData);
    console.log(response)
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setEditingProduct(false);
    let newProducts = products.map(product => product.productId === response.productId ? response : product);
    setProducts(newProducts);
  }


  const openEditProductModal = (e, data) => {
    e.stopPropagation(); // stops application options from rendering when Edit is clicked
    setEditingProduct(true);
    setSelectedData(data);
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-20">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Products </h1>
          <p className="mt-2 text-sm text-gray-700">A list of your products.</p>
        </div>
        {/* <div className="sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
            onClick={openCreateProductModal}
          >
            Add Product
          </button>
        </div> */}
      </div>
      {errorMessage && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 mt-2 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{errorMessage}</span>
        </div>
      )}
      <DynamicTable
        rowData={products}
        loading={loading}
        legend={productsKey}
        hasChildData={false}
        // openCreateChildModal={openCreateApplicationOptionModal}
        // activateDeactivateChild={activateDeactivateApplicationOption}
        // openEditChildModal={openEditApplicationOptionModal}
        // activateDeactivateRow={activateDeactivateApplication}
        openEditRowModal={openEditProductModal}
        openDetailRowModal={openProductDetailModal}
        mobileLegend={mobileProductsKey}
        noDataMessage={"No products have been added"}
        allowExpandOptions={false}
      />
      {editingProduct && (
        <CreateEditModal 
          form={productForm}
          setData={handleChange}
          setBool={handleBool}
          close={closeModal}
          data={selectedData}
          labels={productsKey.dataMap}
          submit={updateProductSubmit}
          errorMessage={errorMessage}
          title={"Edit Product"}
        />
      )}
      {/* {creatingProduct && (
        <CreateEditModal 
          form={productForm}
          setData={handleChange}
          setBool={handleBool}
          close={closeModal}
          data={selectedData}
          labels={productsKey.dataMap}
          submit={createProductSubmit}
          errorMessage={errorMessage}
          title={"Create Product"}
        />
      )} */}
    
      {viewingProductDetail && (
        <CreateEditModal 
          form={detailProductForm}
          close={closeModal}
          data={selectedData}
          labels={productsKey.dataMap}
          errorMessage={errorMessage}
          hideSubmit={true}
          title={"Product Detail"}
        />
      )}
    </div>
  )
}