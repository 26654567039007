
import React, { useState, useEffect } from "react";

import { getLocations } from '../../services/Locations';
import { getStations, createStation, updateStation, createStationAttribute, updateStationAttribute} from '../../services/Stations';
import CreateEditModal from "../../components/CreateEditModal";
import Loader from "../../components/Loader";
import { stationForm , attributeForm} from "../../utils/forms";
import { stationKey } from "../../utils/labelMap";
import DynamicTable from "../../components/DynamicTable";

export default function Stations() {
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const [stations, setStations] = useState([]);
  const [creatingStation, setCreatingStation] = useState(false);
  const [editingStation, setEditingStation] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [pageErrorMessage, setPageErrorMessage] = useState('');
  const [selectedData, setSelectedData] = useState([]);
  const [createFormWithSelectData, setCreateFormWithSelectData] = useState([]);
  const [creatingAttribute, setCreatingAttribute] = useState(false);
  const [editingAttribute, setEditingAttribute] = useState(false);
  const [activeStationId, setActiveStationId] = useState(-1);

  //let user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      const locations = await getLocations();
      const stations = await getStations();
      if (locations.error) {
        setPageErrorMessage(locations);
        setLoading(false)
      } if (stations.error) {
        setPageErrorMessage(stations);
        setLoading(false)
      } else {
        setLocations(locations);
        setStations(stations);
        if (locations.length > 0) {
          addDataToSelectOptions(locations, 'locationIdName', 'locationId')
        }
        setPageErrorMessage(false);
        setLoading(false)
      }
    }
    fetchData();
  }, []);

  const addDataToSelectOptions = (data, key, value) => {
    let createFormCopy = [...stationForm];
    const keyIndex = createFormCopy.findIndex(f => f.name === key);
    const keyOptions = data.map(c => c[value]);
    createFormCopy[keyIndex].options = keyOptions;
    setCreateFormWithSelectData(createFormCopy);
  }

  const openEditStationModal = (e, data) => {
    e.stopPropagation();
    setSelectedData(data);
    setEditingStation(true);
  }

  const openStationModal = () => {
    setSelectedData({});
    setCreatingStation(true);
  }

  const openUserDetailModal = (e, data) => {
    e.stopPropagation();
    setSelectedData(data);
  }

  const closeModal = () => {
    setCreatingStation(false);
    setEditingStation(false);
    setCreatingAttribute(false);
    setEditingAttribute(false);
    setActiveStationId(-1);
    //setViewingUserDetail(false);
    setErrorMessage('');
  }

  const handleBool = (name, boolValue) => {
    setSelectedData(selectedData => ({
      ...selectedData,
      [name]: boolValue
    }));
  };

  const handleChange = e => {
    let { name, value } = e.target;
    setSelectedData(selectedData => ({
      ...selectedData,
      [name]: value
    }));
  };

  const createStationSubmit = async () => {
    setErrorMessage('');
    if (!selectedData['locationId']) {
      setErrorMessage("Please select a Location");
      return;
    }
    if (selectedData['nodeId'].length !== 5) {
      setErrorMessage("Ticket Prefix must be 5 characters");
      return;
    }
    const response = await createStation(selectedData);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setCreatingStation(false);
    const newStations = [...stations, response];
    setStations(newStations);
  }

  const updateStationSubmit = async () => {
    setErrorMessage('');
    if (!selectedData['locationId']) {
      setErrorMessage("Please select a Location");
      return;
    }
    const response = await updateStation(selectedData);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setEditingStation(false);
    setStations(stations.map(station => station.id === response.id ? response : station));
  }

  const openEditAttributeModal = (station) => {
    setEditingAttribute(true);
    setSelectedData(station);
    setActiveStationId(station.parentId);
  }

  const openCreateAttributeModal = (stationId) => {
    setCreatingAttribute(true);
    setSelectedData(stationId);
    setActiveStationId(stationId);
  }

  const createAttributeSubmit = async () => {
    setErrorMessage('');
    let attributeData = { ...selectedData };
    attributeData.parentId = activeStationId;
    const response = await createStationAttribute(attributeData);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setCreatingAttribute(false);
    let newStations = [...stations];
    const stationIndex = stations.findIndex(station => station.id === response.result.parentId);
    const attributes = newStations[stationIndex].attributes;
    newStations[stationIndex].attributes = [...attributes, response.result];
    setStations(newStations);
    setActiveStationId(-1);
  }

  const updateAttributeSubmit = async () => {
    setErrorMessage('');
    let attributeData = { ...selectedData };
    attributeData.parentId = activeStationId;
    const response = await updateStationAttribute(attributeData);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setEditingAttribute(false);
    const stationIndex = stations.findIndex(station => station.id === response.result.parentId);
    const station = stations.filter(station => station.id === response.result.parentId)[0];
    const attributeIndex = station.attributes.findIndex(attribute => attribute.id === response.result.id);
    let newStations = [...stations];
    newStations[stationIndex].attributes[attributeIndex] = response.result;
    setStations(newStations);
  }

  const handleSelect = e => {
    let { name, value } = e.target;
    if (value === 'default') {
      let data = {...selectedData};
      let key;
      let field = e.target.field;
      if (name === 'locationIdName') {
        data['locationId'] = field;
      delete data[key];
      setSelectedData(data);
      return;
      }
    }
    if (name === "locationIdName") {
      const location = locations.filter(c => c.locationId === value);
      setSelectedData(selectedData => ({
        ...selectedData,
        [name]: value,
        locationId: location[0].id
      }));
    }
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-20">
      {loading && (
        <Loader />
      )}
      {pageErrorMessage && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{pageErrorMessage}</span>
        </div>
      )}
      {!pageErrorMessage && (
        <div>
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">Stations</h1>
              <p className="mt-2 text-sm text-gray-700">List of all stations</p>
            </div>
            <div className="sm:mt-0 mt-4 sm:ml-16 sm:flex-none">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
                onClick={() => openStationModal()}
              >
                Add Station
              </button>
            </div>
          </div>
        {errorMessage && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mt-2 rounded relative" role="alert">
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{errorMessage}</span>
          </div>
        )}
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <DynamicTable
              rowData={stations}
              legend={stationKey}
              loading={loading}
              hasChildData={true}
              openEditRowModal={openEditStationModal}
              openCreateChildModal={openCreateAttributeModal}
              openEditChildModal={openEditAttributeModal}
              openDetailRowModal={openUserDetailModal}
              mobileLegend={stationKey}
              noDataMessage={"No Stations have been added"}
              allowExpandOptions={true}
            />
          </div>
        </div>
        {creatingStation && (
          <CreateEditModal 
            form={createFormWithSelectData}
            setData={handleChange}
            close={closeModal}
            setBool={handleBool}
            setSelect={handleSelect}
            data={selectedData}
            labels={stationKey.dataMap}
            submit={createStationSubmit}
            errorMessage={errorMessage}
            title={"Create Station"}
          />
        )}
        {editingStation && (
          <CreateEditModal 
            form={createFormWithSelectData}
            setData={handleChange}
            close={closeModal}
            setBool={handleBool}
            setSelect={handleSelect}
            data={selectedData}
            labels={stationKey.dataMap}
            submit={updateStationSubmit}
            errorMessage={errorMessage}
            title={"Edit Station"}
          />
        )}
        {creatingAttribute && (
          <CreateEditModal 
            form={attributeForm}
            setData={handleChange}
            setBool={handleBool}
            close={closeModal}
            data={selectedData}
            labels={stationKey.childMap}
            submit={createAttributeSubmit}
            errorMessage={errorMessage}
            title={"Create Attribute"}
          />
        )}
        {editingAttribute && (
          <CreateEditModal 
            form={attributeForm}
            setData={handleChange}
            setBool={handleBool}
            close={closeModal}
            data={selectedData}
            labels={stationKey.childMap}
            submit={updateAttributeSubmit}
            errorMessage={errorMessage}
            title={"Edit Attribute"}
          />
        )}
      </div>
      )}
    </div>
  )
}