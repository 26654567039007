import axios from 'axios';
import { formatErrorResponse } from '../utils/formatErrors';

export const getProductList = async () => {

  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.get('/FrontierProduct/Product?includeChildren=false', config);
    return response.data;
  } catch (error) {
    console.log(error)
    return formatErrorResponse(error.response);
  }
}

export const updateProduct = async (data) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.put('/FrontierProduct/Product', data, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}