
import React, { useState, useEffect } from "react";

import {
  createSelfCheckoutUser,
  getSelfCheckoutUsersList,
  resetUserIdAndPassword,
  updateSelfCheckoutUser,
  createUserAttribute,
  updateUserAttribute
} from '../services/FrontierUsers';

import CreateEditModal from "../components/CreateEditModal";
import Loader from "../components/Loader";
import { selfCheckoutUserForm, attributeForm } from "../utils/forms";
import { selfCheckoutUserKey } from "../utils/labelMap";
import DynamicTable from "../components/DynamicTable";
import Modal from 'react-modal';

export default function FrontierUsers() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [creatingUser, setCreatingUser] = useState(false);
  const [editingUser, setEditingUser] = useState(false);
  const [creatingAttribute, setCreatingAttribute] = useState(false);
  const [editingAttribute, setEditingAttribute] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [pageErrorMessage, setPageErrorMessage] = useState('');
  const [selectedData, setSelectedData] = useState([]);
  const [showResetUserModal, setShowResetUserModal] = useState(false);
  const [activeUserId, setActiveUserId] = useState(false);

  let user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      const data = await getSelfCheckoutUsersList(user);
      if (data.error) {
        setPageErrorMessage(data.error);
        setLoading(false)
      } else {
        setUsers(data.result);
        setPageErrorMessage(false);
        setLoading(false)
      }
    }
    fetchData();
  }, []);


  const openEditUserModal = (e, data) => {
    e.stopPropagation();
    setEditingUser(true);
    setErrorMessage("");
    setSelectedData(data);
  }

  const openCreateSelfCheckoutUserModal = () => {
    setSelectedData({});
    setErrorMessage("");
    setCreatingUser(true);
  }

  const openUserDetailModal = (e, data) => {
    e.stopPropagation(); // stops registration options from rendering when Row is clicked
    //setViewingUserDetail(true);
    setSelectedData(data);
  }

  const closeModal = () => {
    setEditingUser(false);
    setCreatingUser(false);
    setCreatingAttribute(false);
    setEditingAttribute(false);
    setErrorMessage('');
  }

  const handleBool = (name, boolValue) => {
    setSelectedData(selectedData => ({
      ...selectedData,
      [name]: boolValue
    }));
  };

  const handleChange = e => {
    let { name, value } = e.target;
    setSelectedData(selectedData => ({
      ...selectedData,
      [name]: value
    }));
  };

  const createSelfCheckoutUserSubmit = async () => {
    setErrorMessage('');
    const response = await createSelfCheckoutUser(selectedData);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setCreatingUser(false);
    const newUsers = [...users, response];
    setUsers(newUsers);
  }

  const updateSelfCheckoutUserSubmit = async () => {
    setErrorMessage('');
    const response = await updateSelfCheckoutUser(selectedData);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setEditingUser(false);
    setUsers(users.map(user => user.id === response.id ? response : user));
  }

  const openResetUserModal = (e, user) => {
    e.stopPropagation();
    setErrorMessage("");
    setShowResetUserModal(true);
    setActiveUserId(user.id);
  }

  const cancelResetUser = () => {
    setErrorMessage("");
    setShowResetUserModal(false);
    setActiveUserId(-1)
  }

  const resetUserSubmit = async () => {
    const user = users.filter(user => user.id === activeUserId)[0]
    const response = await resetUserIdAndPassword(user.email);
    if (response.error) {
      setErrorMessage(response.error);
      setShowResetUserModal(false);
      setActiveUserId(-1);
      return;
    }
    setUsers(users.map(user => user.id === response.id ? response : user));
    setShowResetUserModal(false);
    setActiveUserId(-1);
    setErrorMessage("");
  }

  const openEditAttributeModal = (user) => {
    setErrorMessage("");
    setEditingAttribute(true);
    setSelectedData(user);
    setActiveUserId(user.parentId);
  }

  const openCreateAttributeModal = (userId) => {
    setErrorMessage("");
    setCreatingAttribute(true);
    setSelectedData(userId);
    setActiveUserId(userId);
  }

  const createAttributeSubmit = async () => {
    setErrorMessage('');
    let attributeData = { ...selectedData };
    attributeData.parentId = activeUserId;
    const response = await createUserAttribute(attributeData);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setCreatingAttribute(false);
    let newUsers = [...users];
    const userIndex = users.findIndex(user => user.id === response.parentId);
    const attributes = newUsers[userIndex].attributes;
    newUsers[userIndex].attributes = [...attributes, response];
    setUsers(newUsers);
    setActiveUserId(-1);
  }

  const updateAttributeSubmit = async () => {
    setErrorMessage('');
    let attributeData = { ...selectedData };
    attributeData.parentId = activeUserId;
    const response = await updateUserAttribute(attributeData);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setEditingAttribute(false);
    const userIndex = users.findIndex(user => user.id === response.parentId);
    const user = users.filter(user => user.id === response.parentId)[0];
    const attributeIndex = user.attributes.findIndex(attribute => attribute.id === response.id);
    let newUsers = [...users];
    newUsers[userIndex].attributes[attributeIndex] = response;
    setUsers(newUsers);
  }

  const modalStyles = {
    content: {
      top: '25%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-20">
      {loading && (
        <Loader />
      )}
      <Modal
        isOpen={showResetUserModal}
        style={modalStyles}
      >
        <h2 className="text-center text-emerald-600 font-bold text-2xl mb-2 p-3">Reset User Id and Password</h2>
        <p className="text-center text-gray-500 font-bold mb-5">Would you like to reset the user?</p>
        <div className="flex flex-col justify-center">
          <button
            onClick={() => cancelResetUser()}
            className="flex justify-center py-1 px-1 mb-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Cancel
          </button>
          <button
            onClick={() => resetUserSubmit()}
            className="flex justify-center py-1 px-1 border border-transparent text-sm font-medium rounded-md text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Reset
          </button>
        </div>
      </Modal>
      {pageErrorMessage && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{pageErrorMessage}</span>
        </div>
      )}
      {!pageErrorMessage && (
        <div>
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">PartnerTech Users</h1>
              <p className="mt-2 text-sm text-gray-700">List of PartnerTech SCO users</p>
            </div>
            <div className="sm:mt-0 mt-4 sm:ml-16 sm:flex-none">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
                onClick={() => openCreateSelfCheckoutUserModal()}
              >
                Add User
              </button>
            </div>
          </div>
        {errorMessage && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mt-2 rounded relative" role="alert">
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{errorMessage}</span>
          </div>
        )}
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
              <DynamicTable
                rowData={users}
                legend={selfCheckoutUserKey}
                loading={loading}
                hasChildData={true}
                openEditRowModal={openEditUserModal}
                openDetailRowModal={openUserDetailModal}
                openResetModal={openResetUserModal}
                openCreateChildModal={openCreateAttributeModal}
                openEditChildModal={openEditAttributeModal}
                mobileLegend={selfCheckoutUserKey}
                noDataMessage={"No Self Checkout Users have been added"}
                allowExpandOptions={true}
              />
          </div>
        </div>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
          </div>
        </div>
        {creatingUser && (
          <CreateEditModal 
            form={selfCheckoutUserForm}
            setData={handleChange}
            close={closeModal}
            setBool={handleBool}
            data={selectedData}
            labels={selfCheckoutUserKey.dataMap}
            submit={createSelfCheckoutUserSubmit}
            errorMessage={errorMessage}
            title={"Create User"}
          />
        )}
        {editingUser && (
          <CreateEditModal 
            form={selfCheckoutUserForm}
            setData={handleChange}
            close={closeModal}
            setBool={handleBool}
            data={selectedData}
            labels={selfCheckoutUserKey.dataMap}
            submit={updateSelfCheckoutUserSubmit}
            errorMessage={errorMessage}
            title={"Update User"}
          />
        )}
        {creatingAttribute && (
          <CreateEditModal 
            form={attributeForm}
            setData={handleChange}
            setBool={handleBool}
            close={closeModal}
            data={selectedData}
            labels={selfCheckoutUserKey.childMap}
            submit={createAttributeSubmit}
            errorMessage={errorMessage}
            title={"Create Attribute"}
          />
        )}
        {editingAttribute && (
          <CreateEditModal 
            form={attributeForm}
            setData={handleChange}
            setBool={handleBool}
            close={closeModal}
            data={selectedData}
            labels={selfCheckoutUserKey.childMap}
            submit={updateAttributeSubmit}
            errorMessage={errorMessage}
            title={"Edit Attribute"}
          />
        )}
      </div>
      )}
    </div>
  )
}