export const applicationKey = {
    dataMap: {
        applicationId: {column: "Name", type: "string"},
        applicationDescription: {column: "Description", type: "string"},
        serialNumberPrefix: {column: "Serial Number Prefix", type: "string"},
        serialNumberSuffix: {column: "Serial Number Suffix", type: "string"},
        lastSerialNumberIssued: {column: "Last Serial Number Issued", type: "string"},
        maxKeyAge: {column: "Max Key Age", type: "string"},
        isActive: {column: "Is Active", type: "toggle"},
        hasUserCount: {column: "Has User Count", type: "bool"},
        requireAPIKey: {column: "Require API Key", type: "bool"}
    },
    childMap: {
        optionId: {column: "Option Id", type: "string"},
        optionName: {column: "Option Name", type: "string"},
        optionDescription: {column: "Option Description", type: "string"},
        isActive: {column: "Is Active", type: "toggle"}
    },
    childField: "applicationOptions"
}

export const mobileApplicationKey = {
    dataMap: {
        applicationId: {column: "Name", type: "string"},
        applicationDescription: {column: "Description", type: "string"},
        hasUserCount: {column: "Has User Count", type: "bool"}
    },
    childMap: {
        optionId: {column: "Option Id", type: "string"},
        optionName: {column: "Option Name", type: "string"},
    },
    childField: "applicationOptions"
}

export const physicalCountKey = {
    dataMap: {
        sku: {column: "SKU", type: "string"},
        location: {column: "Location", type: "string"},
        count: {column: "Count", type: "string"},
    },
    childMap: {
        identifier: {column: "Identifier", type: "string"},
        count: {column: "Count", type: "string"},
        createdByUsername: {column: "User", type: "string", isSortable: true},
        location: {column: "Location", type: "string"},
        createdOn: {column: "Creation Date", type: "datetime"}
    },
    childField: "physicalCountChildren"
}

export const licenseKey = {
    dataMap: {
        applicationIdName: {column: "Application", type: "string"},
        companyIdName: {column: "Company", type: "string"},
        notes: {column: "Notes", type: "string"},
        serialNumber: {column: "Serial Number", type: "string", hasClipboard: true},
        status: {column: "Status", type: "string"},
        lastContact: {column: "Last Contact", type: "dateTime"},
        isUpdateRequired: {column: "Force Registration Update", type: "bool"},
        reset: {column: 'Reset', type: "reset"},
        isActive: {column: "Is Active", type: "toggle"},
        lastApplicationVersion: {column: "Version", type: "string"},
        initializationDate: {column: "Creation Date", type: "dateTime"},
        expirationDate: {column: "Expiration Date", type: "dateTime"},
        userCount: {column: "User Count", type: "string"}
    },
    childMap: {
        applicationOptionIdName: {column: "Option Name", type: "parent"}
    },
    childField: "licenseOptions"
}

export const mobileLicenseKey = {
    dataMap: {
        applicationIdName: {column: "Application", type: "string"},
        companyIdName: {column: "Company", type: "string"},
        notes: {column: "Notes", type: "string"},
        status: {column: "Status", type: "string"},
        serialNumber: {column: "Serial Number", type: "string", hasClipboard: true},
        userCount: {column: "User Count", type: "string"}
    },
    childMap: {
        applicationOptionIdName: {column: "Option Id", type: "parent"}
    },
    childField: "licenseOptions"
}

export const deviceRegistrationKey = {
    dataMap: {
        companyIdName: {column: "Company", type: "string"},
        deviceToken: {column: "Device Token", type: "string", hasClipboard: true},
        deviceName: {column: "Device Name", type: "string"},
        applicationIdName: {column: "Application", type: "string"},
        username: {column: 'User', type: "string"},
        firstContactDate: {column: "First Contact", type: "dateTime"},
        reset: {column: 'Reset', type: "reset"},
        lastContacted: {column: "Last Contact", type: "dateTime"},
        registeredDate: {column: "Registered Date", type: "dateTime"},
        isUpdateRequired: {column: "Force Registration Update", type: "bool"},
        isActive: {column: "Status", type: "toggle"},
    },
    childMap: {},
    childField: ""
}

export const endUserDeviceRegistrationKey = {
    dataMap: {
        companyIdName: {column: "Company", type: "string"},
        deviceToken: {column: "Device Token", type: "string", hasClipboard: true},
        deviceName: {column: "Device Name", type: "string"},
        applicationIdName: {column: "Application", type: "string"},
        reset: {column: 'Reset', type: "reset"},
        lastContacted: {column: "Last Contact", type: "dateTime"},
        registeredDate: {column: "Registered Date", type: "dateTime"}
    },
    childMap: {},
    childField: ""
}

export const mobileDeviceRegistrationKey = {
    dataMap: {
        companyIdName: {column: "Company", type: "string"},
        deviceToken: {column: "Device Token", type: "string", hasClipboard: true},
        deviceName: {column: "Device Name", type: "string"},
        applicationIdName: {column: "Application", type: "string"}
    },
    childMap: {},
    childField: ""
}

export const companiesKey = {
    dataMap: {
        companyId: {column: "Id", type: "string"},
        name: {column: "Name", type: "string"},
        url: {column: "URL", type: "string"},
        sqlServerId: {column: "SQL Server Id", type: "string"},
        sqlDatabaseName: {column: "SQL Database Name", type: "string"},
        isActive: {column: "Is Active", type: "toggle"}
    }
}

export const mobileCompaniesKey = {
    dataMap: {
        companyId: {column: "Id", type: "string"},
        name: {column: "Name", type: "string"},
        sqlServerId: {column: "SQL Server Id", type: "string"},
        sqlDatabaseName: {column: "SQL Database Name", type: "string"}
    },
    childMap: {},
    childField: ""
}

export const productsKey = {
    dataMap: {
        sku: {column: "Id", type: "string"},
        name: {column: "Name", type: "string"},
        minimumAgeRequirement : {column: "Minimum Required Age", type: "string"},
    }
}

export const mobileProductsKey = {
    dataMap: {
        sku: {column: "Id", type: "string"},
        name: {column: "Name", type: "string"},
        minimumAgeRequirement : {column: "Minimum Required Age", type: "string"},
    },
    childMap: {},
    childField: ""
}

export const usersKey = {
    dataMap: {
        firstName: {column: "First Name", type: "string"},
        lastName: {column: "Last Name", type: "string"},
        email: {column: "Email", type: "string"},
        isActive: {column: "Is Active", type: "toggle"}
    }
}

export const mobileUsersKey = {
    dataMap: {
        firstName: {column: "First Name", type: "string"},
        lastName: {column: "Last Name", type: "string"},
        email: {column: "Email", type: "string"},
    },
    childMap: {},
    childField: ""
}

export const selfCheckoutUserKey = {
    dataMap: {
        email: {column: "Email", type: "string"},
        name: {column: "Name", type: "string"},
        userId: {column: "UserId", type: "string"},
        password: {column: "Password", type: "string"},
        badgeBarcode: {column: "Badge", type: "string"},
        reset: {column: 'Reset', type: "reset"},
    },
    childMap: {
        name: {column: "Name", type: "string"},
        value: {column: "Value", type: "string"},
    },
    childField: "attributes"
}

export const locationKey = {
    dataMap: {
        locationId: {column: "Email", type: "string"},
        description: {column: "Description", type: "string"},
        profitCenter: {column: "Profit Center", type: "string"},
        regionId: {column: "Region Id", type: "string"},
        isValidStocking: {column: "Is Valid Stocking", type: "bool"},
        isValidSelling: {column: "Is Valid Selling", type: "bool"},
        isValidPurchasing: {column: "Is Valid Purchasing", type: "bool"},
        isActive: {column: "Is Active", type: "bool"},
        primaryContactId: {column: "Primary Contact", type: "string"}
    },
    childMap: {
        name: {column: "Name", type: "string"},
        value: {column: "Value", type: "string"},
    },
    childField: "attributes"
}

export const stationKey = {
    dataMap: {
        name: {column: "Name", type: "string"},
        description: {column: "Description", type: "string"},
        locationIdName: {column: "Location", type: "string"},
        nodeId: {column: "Ticket Prefix", type: "string"},
        ticketNumberSuffix: {column: "Ticket Suffix", type: "string"},
        isEnabled: {column: "Is Enabled", type: "bool"}
    },
    childMap: {
        name: {column: "Name", type: "string"},
        value: {column: "Value", type: "string"},
    },
    childField: "attributes"
}

export const ticketKey = {
    dataMap: {
        ticketType: {column: "Type", type: "string"},
        ticketNumber: {column: "Ticket #", type: "string"},
        locationId: {column: "Location", type: "string"},
        stationName: {column: "Station", type: "string"},
        ticketDate: {column: "Ticket Date", type: "dateTime"},
        total: {column: "Total", type: "money"},
        isPosted: {column: "Posted", type: "bool"}
    },
    childMap: {
        lineSequenceNumber: {column: "Line", type: "string"},
        lineType : {column: "Type", type: "string"},
        productSku: {column: "Sku", type: "string"},
        productKey: {column: "Key", type: "string"},
        barcode: {column: "Barcode", type: "string"},
        quantity: {column: "Quantity", type: "string"},
        price: {column: "Price", type: "money"}
    },
    childField: "lines"
}

//"ticketNumber": "123456789012345",
//"locationId": "TestLocation",
//"stationName": "TestStation",
//"customerId": null,
//"drawerId": null,
//"salesPersonId": null,
//"ticketDate": "2023-11-15T11:17:34-05:00",
//"isPosted": true,
//"ticketType": "Order",
//"transactionDateTime": "2023-11-15T11:17:34-05:00",
//"ticketGuid": "c9b71f18-4077-484f-aced-006616de4c50",
//"lines": [
//  {
//    "lineSequenceNumber": 1,
//    "productSku": "ADM-TL2",
//    "productKey": [],
//    "quantity": 2,
//    "calculatedPrice": null,
//    "barcodePrice": null,
//    "barcodeExtendedPrice": null,
//    "enteredPrice": null,
//    "priceType": 0,
//    "barcode": "789",
//    "lineType": 0,
//    "voidedById": null,
//    "returnedById": null
//  }
//],
//"attributes": [],
//"payments": [],
//"createdOn": null,
//"modifiedOn": null,
//"id": 0,
//"createdByUserId": null,
//"modifiedByUserId": null